import React from 'react';

import ReactDOM from 'react-dom';

export enum zIndex {
  MODAL = 999,
  SIDEBAR = 998,
  HEADER = 997,
}

export type BaseModalProps = {
  visible?: boolean;
  children: React.ReactNode;
  className?: string
  onHide?: () => void;
}
const modalPortal = document.getElementById('modal-portal');
export const ImageModal: React.FC<BaseModalProps> = (
  {
    visible,
    children,
    onHide,
    className,
  }) => {
  return <>
    {ReactDOM.createPortal(
      <div>
        {visible && <>
          <div
            onClick={() => onHide?.()}
            style={{
              position: 'relative',
              cursor: 'pointer',
              zIndex: zIndex.MODAL,
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(rgba(188, 188, 188, 0.5) 0%, rgba(75, 75, 75, 0.9) 100%)',
              }}
            />

            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '10px',
                ...(className ? {className} : {}),
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  width: '100%',
                  maxWidth: '100%',
                  borderRadius: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      paddingRight: '6px',
                      overflowY: 'auto',
                    }}
                  >
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
      </div>,
      modalPortal as any)
    }
  </>;
};
