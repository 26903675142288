import React, {useEffect, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {ApiRequestException} from '../../api/axios-instance';
import {ISupportTicketDto} from '../../api/support-tickets-api/ISupportTicketDto';
import {ISupportTicketMessage} from '../../api/DTOs/ISupportTicketMessage';
import {FormattedMessage, useIntl} from 'react-intl';
import {SupportTicketsApi} from '../../api/support-tickets-api/support-tickets-api';
import {ErrorStub} from '../../components/error-stub';
import {AdminRoutes} from '../../../configs/routes';
import {shallowEqual, useSelector} from 'react-redux';
import {IApplicationStore} from '../../../redux/rootReducer';
import cn from 'classnames';
import {CustomFormatter} from '../../../localization/custom-formatter';
import {IUserDto, UserType} from '../../api/DTOs/IUserDto';
import {toast} from 'react-toastify';
import {Preloader} from '../../components/preloader';
import {LinkWithClickControl} from '../../components/link-with-click-control';
import {RichTextEditor} from '../../components/rich-text-editor/rich-text-editor';

import './ticket-card-page.scss';
import {useMediaQuery} from 'usehooks-ts';
import {ImageModal} from './image-modal';


interface IProps {
  isAdmin?: boolean;
}

const TEMP_MESSAGE_ID = -1;

export const TicketCardPage: React.FC<IProps> = ({isAdmin}) => {
  const isSmDisplay = useMediaQuery('(max-width: 1150px)');
  const api = new SupportTicketsApi();
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const ticketId = Number(match.params.id);
  const userDto = useSelector<IApplicationStore, IUserDto>(({auth}) => auth.user!, shallowEqual);

  const [ticket, setTicket] = useState<ISupportTicketDto | null>(null);
  const [messages, setMessages] = useState<Array<ISupportTicketMessage>>([]);
  const [loadingSendMessage, setLoadingSendMessage] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages.length]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    const fetchAll = async () => {
      await fetchTicket();
      intervalId = setInterval(async () => {
        if (!loadingSendMessage) {
          await fetchTicket();
        }
      }, 5000);
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchAll();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const handleClick = (event: any) => {
    if (event.target.tagName === 'IMG') {
      const imageSrc = event.target.src;
      setBase64Image(imageSrc);
    }
  };

  const fetchTicket = async () => {
    try {
      const response = await api.getTicket(Number(ticketId));
      setTicket(response.data.item);
      setMessages(response.data.relations.messages.reverse());
    } catch (e: any) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const scrollToLastMessage = (node: HTMLDivElement) => {
    node.scrollIntoView({behavior: 'smooth'});
  };

  const handleChangeMessage = (content: string) => {
    setMessage(content);
  };

  const handleSendMessage = async () => {
    try {
      setLoadingSendMessage(true);
      const tempMessage: Partial<ISupportTicketMessage> = {
        id: TEMP_MESSAGE_ID,
        message: message,
        authorId: userDto.id,
        authorName: userDto.email,
        createdAt: new Date().toISOString(),
      };

      setMessages([...messages, tempMessage as ISupportTicketMessage]);
      await api.sendMessage(ticketId, message);
      setMessage('');
      await fetchTicket();
    } catch (e: any) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoadingSendMessage(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (!ticket || error) {
    return <ErrorStub error={error || intl.formatMessage({id: 'UNEXPECTED_ERROR'})} />;
  }

  const renderTicketHeader = () => {
    return <>
      <div className={'d-flex align-items-center mb-4'}>
        <div className="d-flex flex-column">
          <h3 className="text-gray-800 font-weight-bold">
            <span className={'text-muted'}></span>
            <LinkWithClickControl
              disabled={!isAdmin}
              to={AdminRoutes.getSpecifyUserManagementRoute(ticket.authorId)}
              className="text-muted text-hover-primary font-weight-bolder">
              {ticket.authorName}
            </LinkWithClickControl>
          </h3>
        </div>
      </div>
    </>;
  };

  const renderForm = () => {
    return <div>
      <RichTextEditor
        disabled={loadingSendMessage}
        placeholder={intl.formatMessage({id: 'WRITE_MESSAGE'})}
        content={message}
        className={'mb-3'}
        onChange={handleChangeMessage}
      />

      <button
        disabled={loadingSendMessage}
        onClick={handleSendMessage}
        className={cn('btn btn-primary', {
          'w-100': isSmDisplay,
        })}>
        <FormattedMessage id={'SEND'} />
      </button>
    </div>;
  };

  const renderMessages = () => {
    return <div
      className={'mb-4'}
      style={{
        height: isSmDisplay ? 'calc(100vh - 370px)' : 'calc(100vh - 420px)', overflowY: 'scroll',
        paddingRight: isSmDisplay ? '0px' : '12px',
    }}>
      {messages.map((message, index) => {
        const isLastMessage = index === messages.length - 1;
        const isSelfMessage = userDto.id === message.authorId || userDto.type === UserType.ADMIN && message.authorName.includes('Администратор');
        return <div
          key={message.id}
          id={message.id.toString()}
          ref={(r) => {
            if (isLastMessage) {
              if (lastMessageRef.current?.getAttribute('id') === message.id.toString()) {
                return;
              } else {
                lastMessageRef.current = r;
                scrollToLastMessage(lastMessageRef.current as HTMLDivElement);
              }
            }
          }}
          className={cn('mb-3 w-100 d-flex', {
            'justify-content-end': isSelfMessage,
          })}>
          <div className={'card card-bordered'}
               style={{
                 width: 'fit-content', minWidth: '30%',
                 backgroundColor: isSelfMessage ? 'rgba(242,253,255,0.4)' : 'rgba(202,255,191,0.05)',
               }}>
            <div className={'card-body'}
                 style={{paddingTop: '8px', paddingBottom: '8px', paddingLeft: '12px', paddingRight: '12px'}}>
              <div className={'w-100 d-flex flex-stack'} style={{marginBottom: '2px'}}>
                <div className={'d-flex align-items-center'}>
                  <div className="d-flex flex-column fw-bold fs-5 text-gray-600 text-dark">
                    <div className="d-flex align-items-center" style={{fontWeight: 600}}>
                      <LinkWithClickControl
                        disabled={!isAdmin}
                        to={AdminRoutes.getSpecifyUserManagementRoute(message.authorId)}
                        className={cn('text-gray-800 text-hover-primary', {
                          'text-success': !isSelfMessage,
                          'text-primary': isSelfMessage,
                        })}>
                        {message.authorName}
                      </LinkWithClickControl>
                    </div>
                    <span className="text-muted font-weight-bold" style={{fontSize: 10}}>
                    {CustomFormatter.formatDateTimeWithFullMonth(message.createdAt)}
                  </span>
                  </div>
                </div>
              </div>
              <div
                onClick={handleClick}
                className={'message-content font-weight-bold text-gray-700 m-0 font-size-lg'}
                dangerouslySetInnerHTML={{__html: message.message}}
              />
            </div>
          </div>
        </div>;
      })
      }
    </div>;
  };

  const renderTicketBody = () => {
    return <div style={{maxWidth: '1480px'}}>
      {renderMessages()}
      {renderForm()}
    </div>;
  };

  return <>
    <div className={'card h-100'}>
      <div className={'card-body'} style={{
        paddingLeft: isSmDisplay ? '10px' : undefined,
        paddingRight: isSmDisplay ? '10px' : undefined,
        paddingTop: isSmDisplay ? '10px' : undefined,
        paddingBottom: isSmDisplay ? '10px' : undefined,
      }}>
        <div className="d-flex flex-column flex-md-column-reverse flex-xl-row">
          <div className={'flex-lg-row-fluid mb-xl-0'}>
            <div className={'mb-0'}>
              {renderTicketHeader()}
              {renderTicketBody()}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ImageModal visible={base64Image != null} onHide={() => setBase64Image(null)}>
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
      }}>
        <img style={{maxWidth: '100%', maxHeight: '100%'}} src={base64Image as any} alt="attachment" />
      </div>
    </ImageModal>
  </>;
};

