import {EntityId} from '../base/BaseEntity';

export enum UserType {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface IUserDto {
  id: number;
  email: string;
  type: UserType;

  contractor_id: EntityId | null;
  contractor_name: string | null;

  hasPaymentRequisites: boolean;
  hasActiveChannel: boolean;
  hasMcPayCredentials: boolean;
  isJurisdictionWithTwiceLanguageContract: boolean;
  allow_payment_request_by_requisites: boolean;

  created_at: string;
  updated_at: string;
  blocked_at: string | null;
  ticket_id: number | null;
}
