import {ReportsProcessingStatus} from '../../app/api/financial-report-api/IYoutubeFinancialBulkReportsDto';
import {ContractStatus} from '../../app/api/contract-api/IContractDto';
import {PaymentRequestDestination, PaymentRequestStatus} from '../../app/api/payment-api/IPaymentRequestDto';
import {FilterRequestStatus} from '../../app/api/connect-channel-api/FilterRequestStatus';
import {
  McPayCreateTransactionStatus,
  TransactionStatus,
  TransactionType,
} from '../../app/api/transaction-api/Transaction';
import {SupportTicketStatus, SupportTicketType} from '../../app/api/support-tickets-api/ISupportTicketDto';
import {AdsStatus} from '../../app/api/advertisement-api/IAdvertisementRequestDto';
import {ArticleStatus} from '../../app/api/knowledge-base-api/IArticleDto';

export default {
  'BUTTONS.SUBMIT': 'Submit',
  'BUTTONS.CANCEL': 'Cancel',
  'AUTH.GENERAL.WELCOME': 'Welcome to Play Network',
  'AUTH.GENERAL.WELCOME_SUBTITLE': 'The World Biggest Ecosystem for Creators',
  'AUTH.GENERAL.NO_ACCOUNT': 'Don\'t have an account?',
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Sign Up',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Forgot Password',
  'AUTH.GENERAL.BACK_BUTTON': 'Back',
  'AUTH.GENERAL.ALREADY_HAVE_ACCOUNT': 'Already have account?',
  'AUTH.LOGIN.TITLE': 'Login Account',
  'AUTH.LOGIN.BUTTON': 'Sign In',
  'AUTH.FORGOT.TITLE': 'Forgotten Password?',
  'AUTH.LOGIN.SUB_TITLE': 'Enter your email and password',
  'AUTH.FORGOT.DESC': 'Enter your email to reset your password',
  'AUTH.REGISTER.TITLE': 'Sign Up',
  'AUTH.REGISTER.DESC': 'Enter your details to create your account',
  'AUTH.REGISTER.AGREEMENT': 'I agree the Terms & Conditions',
  'AUTH.REGISTER.UNEXPECTED_ERROR': 'Sorry, something was wrong. Please repeat again.',
  'AUTH.INPUT.EMAIL': 'Email',
  'AUTH.INPUT.PASSWORD': 'Password',
  'AUTH.INPUT.JURISDICTION': 'Jurisdiction',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Confirm Password',
  MC_PAY_INTEGRATION_ENABLED: 'On',
  MC_PAY_INTEGRATION_DISABLED: 'Off',

  ID: 'ID',
  NAME: 'Name',
  USER: 'User',
  ADMIN: 'Admin',
  USER_PROFILE: 'Profile',
  LOGIN_ERROR: 'Password or email is wrong',
  SUBMIT: 'Submit',
  CANCEL: 'Cancel',
  NOT_FOUND: 'Empty',
  DELETE: 'Delete',
  BLOCK: 'Block',
  UNBLOCK: 'Unblock',
  DOWNLOAD: 'Download',
  TRY_AGAIN: 'Try again',
  TRY_DOWNLOAD_AGAIN: 'Try download again',
  CREATE_CONTRACT: 'Create contract',
  CLOSE: 'Close',
  EDIT: 'Edit',
  CREATE: 'Create',
  CLEAR: 'Clear',

  YOUTUBE_CHANNELS: 'YouTube channels',
  YOUTUBE_CHANNEL: 'YouTube channel',
  YOUTUBE_CHANNEL_URL: 'YouTube channel link',
  YOUTUBE_CHANNEL_URL_PLACEHOLDER: 'Insert the link to your YouTube channel',
  YOUTUBE_ALL_VIDEOS: 'YouTube all videos',
  USER_MANAGEMENT: 'Users',
  KNOWLEDGE_BASE: 'FAQ',
  ACCOUNT: 'Account',
  PAYOUTS: 'Payouts',
  REVENUES: 'Revenues',
  REVENUE_DYNAMIC: 'Dynamics of income for the last 6 months',
  CONTRACTS: 'Contracts',
  CONTRACT: 'Contract',
  CONTRACT_REFERRAL: 'Referral contract',
  OPERATIONS_OF_ACCOUNTS: 'Operations on the account',

  CARDS: 'Cards',
  TABLE: 'Table',
  SORT_FIELD: 'Sort',
  CHANNELS: 'Channels',
  CHANNELS_PERCENT_RATES: 'Channels Info',

  TITLE: 'Title',
  LIKES: 'Likes',
  DISLIKES: 'Dislikes',
  VIEWS: 'Views',
  COMMENTS: 'Comments',
  SUBSCRIBERS: 'Subscribers',
  NEW_FIRST: 'New first',
  OLD_FIRST: 'Old first',
  UPDATED_FIRST: 'Updated first',
  UPDATED_LAST: 'Updated last',
  INNER_FIRST: 'Inner first',
  CACHE_FIRST: 'Cache first',

  ASC: 'ascending',
  DESC: 'descending',

  VIDEOS: 'Videos',
  METRICS: 'Metrics',

  LAST7DAYS: 'Last 7 days',
  LAST14DAYS: 'Last 14 days',
  LAST28DAYS: 'Last 28 days',
  LAST90DAYS: 'Last 90 days',

  WEEK: 'Week',
  QUARTER: 'Quarter',
  MONTH: 'Month',
  YEAR: 'Year',

  SUPPORT: 'Support',
  LOGOUT: 'Logout',

  RESET_PASSWORD: 'Reset password',
  SUCCESS_SENT_RESET_PASSWORD_EMAIL:
    'An email with instructions for password recovery has been sent to the email address you specified',
  SUCCESS_SEND_GENERATE_REPORT_REQUEST: 'The report will be generated and sent to your email shortly',
  NOT_FOUND_THIS_ACCOUNT: 'Couldn\'t find your account',
  UNEXPECTED_ERROR: 'Sorry, something was wrong. Please repeat again.',

  CREATE_NEW_PASSWORD: 'Create new password',
  PROFILE: 'Profile',
  CHANGE_PASSWORD: 'Change password',
  SUCCESS_CHANGE_PASSWORD: 'Password was successful change',
  NEW_PASSWORD: 'New password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  CURRENT_PASSWORD: 'Current password',

  CONNECT: 'Connect',
  CONNECTED: 'Connected',
  JOINING: 'Joining',
  SEND_JOINING_REQUEST: 'Leave a request to connect your YouTube channel',
  PROMOTION: 'Promotion',
  ADS_INTEGRATION: 'Advertising integration',
  EXPERIENCE: 'Experience',
  INTEGRATE_WITH_YOUTUBE: 'YouTube Integration',
  INTEGRATE_WITH_YOUTUBE_DESCRIPTION: 'Provide access to your google account in order to activate basic functionality',
  SUCCESS_COMPLETE_YOUTUBE_INTEGRATION: 'Integration successfully completed',
  ERROR_COMPLETE_YOUTUBE_INTEGRATION: 'Failed to integrate',

  SUCCESS_UPLOAD_REPORTS: 'Upload reports successfully completed',
  ERROR_UPLOAD_REPORTS: 'An error occurred while uploading the reports',

  ADMINISTRATE: 'Administrate',
  YOUTUBE_INTEGRATION: 'YouTube integration',
  TRANSACTIONS: 'Transactions',
  DASHBOARD: 'Dashboard',

  EXPIRED: 'Expired',
  TOKEN_TYPE: 'Token type',

  FINANCIAL_REPORTS: 'Financial reports',
  FINANCIAL_REPORT: 'Financial report',
  REPORTS_UPLOAD: 'Upload reports',
  YOUTUBE_FINANCIAL_REPORTS: 'Import financial reports',
  REPORT_PERIOD: 'Upload period',
  LABEL_PERIOD: 'Select period',
  LABEL_CMS: 'Select CMS',
  DROP_FILES: 'Drop files here or click to upload',
  GO: 'Перейти',

  FILE_NAME: 'File name',
  SIZE: 'Size',
  REPORT_TYPE: 'Type',
  CREATED_AT: 'Created at',
  UPDATED_AT: 'Updated at',
  BLOCKED_AT: 'Blocked at',
  USER_TYPE: 'Type',
  EMAIL: 'Email',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  GET_USERS_ERROR: 'Unexpected error when receiving users',
  CONFIRM_DELETE_USER: 'Are you sure you want to delete the user?',
  CONFIRM_BLOCK_USER: 'Are you sure you want to block the user?',
  CONFIRM_UNBLOCK_USER: 'Are you sure you want to unblock the user?',
  CONFIRM_RESET_PASSWORD_USER: 'Are you sure you want to reset password the user?',
  SUCCESS_COMPLETE_ACTION_WITH_USER: 'Action successfully completed',
  ERROR_COMPLETE_ACTION_WITH_USER: 'Failed to action',

  YOUTUBE_REPORT_TYPE_VIDEO_SUMMARY: 'Ads revenue → Video Summary',
  YOUTUBE_REPORT_TYPE_RED_RAWDATA_VIDEO: 'Subscription revenue → Non-music Videos',
  YOUTUBE_REPORT_TYPE_RED_MUSIC_RAWDATA_VIDEO: 'Subscription revenue → Assets',
  YOUTUBE_REPORT_TYPE_ADJ_ASSET_SUMMARY: 'Ads adjustments revenue → Asset Summary',
  YOUTUBE_REPORT_TYPE_ECOMMERCE_PAID_FEATURES: 'Income from paid features → Paid Features Report',
  YOUTUBE_REPORT_TYPE_SHORTS_ADS: 'YouTube Shorts -> Ads',
  YOUTUBE_REPORT_TYPE_SHORTS_SUBSCRIPTION: 'YouTube Shorts -> Subscriptions',

  [ReportsProcessingStatus.CREATED]: 'Created',
  [ReportsProcessingStatus.IN_WORK]: 'In work',
  [ReportsProcessingStatus.ERROR]: 'Error',
  [ReportsProcessingStatus.PROCESSED]: 'Processed',

  CONTRACTOR: 'Contractor',
  CONTRACTORS: 'Contractors',
  USERS: 'Users',
  ACCOUNTS: 'Accounts',
  SUM: 'Sum',
  FROM: 'From',
  TO: 'To',

  TOGGLE_MENU: 'Show/Hide Menu',

  CURRENT_BALANCE: 'Current balance',
  DESCRIPTION: 'Description',
  ORIGINAL_DESCRIPTION: 'Original description',
  FINANCIAL_ACCOUNTS: 'Financial accounts',
  BALANCE: 'Balance',
  ACCOUNT_TYPE: 'Account type',
  INNER_ACCOUNT: 'Inner account',
  CACHE_ACCOUNT: 'Cache account',
  MC_PAY_ACCOUNT: 'McPay account',
  CONTRACT_NUMBER: 'Number',
  VALIDITY_PERIOD: 'Validity period',
  CONTRACT_START_DATE: 'Start date',
  CONTRACT_END_DATE: 'End date',
  CONTRACT_NUMBER_BE_AUTOGENERATED: 'The contract number will be generated automatically',
  CHANNEL_NAME: 'Channel name',
  CHANNEL: 'Channel',
  ADDENDUM_NUMBER: 'Number',
  ADDENDUM_LIST: 'Addendum list',
  RATE_PERCENT: 'Rate percent',
  RATE_PERCENT_SHORT: 'Rate (%)',
  CREATE_ADDENDUM: 'Create addendum',
  ADDENDUM: 'Addendum',
  ADDENDUMS: 'Addendums',
  SOURCE: 'Source',
  CONTRACTOR_EMAIL: 'Contractor email',
  CONTRACTOR_NAME: 'Contractor name',
  USER_NAME: 'User name',
  EDIT_CONTRACT: 'Edit contract',
  EDIT_CONTRACTOR: 'Edit contractor',
  CREATE_USER: 'Create user',
  EDIT_USER: 'Edit user',
  PASSWORD: 'Password',
  PASSWORD_CONFIRMATION: 'Confirm Password',
  ADMIN_PRIVILEGES: 'Admin privileges',
  USERS_ACTIONS: 'Users actions',
  STATUS_CODE: 'Status code',
  SHOW_MORE: 'Show more',
  AGENT: 'Agent',
  TIME: 'Time',
  TYPE: 'Type',
  PAYMENT_REQUEST: 'Payment request',
  MC_PAY_PAYMENT_REQUEST: 'Payment request to mcpay.io',
  PAYMENT_REQUESTS: 'Payment requests',
  SUCCESS_PAYMENT_REQUEST: 'Payment request successfully created',
  SEND: 'Send',
  PERFORM: 'Perform',
  REJECT: 'Reject',
  REJECT_REASON: 'Reject reason',
  CANCEL_REASON: 'Cancel reason',
  OPERATION_WAS_CANCELED: 'This operation was canceled',
  DESCRIBE_REJECT_REASON: 'Describe the reject reason',
  DESCRIBE_CANCEL_REASON: 'Describe the cancel reason',
  ADD_YOUTUBE_CHANNEL: 'Adding a YouTube Channel',
  ADD_YOUTUBE_CHANNEL_DESCRIPTION: 'Provide access to Playnetwork services to help your YouTube channel grow.',
  ADD: 'Add',
  ADD_CHANNEL_REQUESTS: 'Requests to connect YouTube channels',
  PUBLISHED_AT: 'Published at',
  APPROVE: 'Approve',
  PERIOD: 'Period',
  ACCOUNT_OPERATIONS: 'Account operations',
  ADS: 'Ads',
  ADS_copies: 'Ads (Copies)',
  COMPENSATIONS: 'Compensations',
  YOUTUBE_PREMIUM: 'YouTube Premium',
  PAID_FEATURES: 'Paid features',
  OTHER_REVENUES: 'Other revenues',
  YOUTUBE_SHORTS: 'Shorts',
  TOTAL: 'Total',

  MIN_PAYOUT_AMOUNT_IS: 'The min payout amount ',
  OF: 'of',
  DRAW_SIGNATURE: 'Draw signature',
  SIGN: 'Sign',
  SIGNATURE: 'Signature',
  REQUISITES: 'Requisites',
  REPORT: 'Report',
  PAYMENT_REQUISITES: 'Payment requisites',
  UPDATE_HISTORY: 'History of changes',
  SWIFT_NUMBER: 'SWIFT/BIC/Routing',
  ACCOUNT_NUMBER: 'Account number',
  MONTHS: 'Months',
  GENERATE_REPORT: 'Generation financial report',
  GENERATE: 'Generate',
  EDIT_PAYMENT_REQUISITES: 'Edit payment requisites',
  ADDRESS: 'Address',
  SHOW_REQUESTS: 'Show my requests',
  CONNECT_CHANNELS_REQUESTS: 'YouTube Channels connection requests',
  SUCCESS_SENT_CONNECT_CHANNEL_REQUEST:
    'The channel connection request has been successfully created. It will be processed by the administrator soon.',
  ERROR_SENT_CONNECT_CHANNEL_REQUEST:
    'The channel connection failed. Make sure the channel has not been connected before, or try again later.',
  NOTICE_EDIT_RESTRICTED: 'Editing of some fields is blocked because there are transactions on this addendum.',
  EDIT_ADDENDUM: 'Edit addendum',
  TRANSACTION_SUM_PREVIOUS_MONTH: 'The amount of transactions for the previous month',
  TRANSACTION_SUM_CURRENT_MONTH: 'The amount of transactions for the current month',
  NOTICE_ACCRUALS_IN_USD: 'To calculate income, you need to specify a USD account',
  UNCONFIRMED_BALANCE: 'Unconfirmed balance',
  DOWNLOAD_XLSX_REPORT: 'Download XLSX report',
  DOWNLOAD_XLSX_REPORT_ON_TRANSACTIONS: 'Download XLSX report on transactions',
  CHANNEL_TRANSACTIONS_INCOME_REPORT: 'Generate channel income report',
  REQUESTS: 'Requests',

  CONFIRM_ALL_TRANSACTIONS: 'Confirm all transactions',
  CREATE_TRANSACTION: 'Create transaction',
  CREATE_ACTION_WITHDRAWING_TRANSACTION: 'Create Withdrawing transaction',
  CREATE_ACTION_TAX_TRANSACTION: 'Create Tax transaction',
  CREATE_ACTION_INCOME_TRANSACTION: 'Create Income transaction',
  ADVERTISEMENT: 'Placement of advertising',
  CREATE_ACTION_DEBIT_TRANSACTION: 'Create Debit transaction',
  CONFIRM_TRANSACTION: 'Are you sure you want to confirm the transaction?',
  CONFIRM_TRANSACTION_DESCRIPTION: 'The user will receive a notification about the accrual to his account.',
  CONFIRM_TRANSACTION_SUCCESS: 'The transaction was successfully completed',
  REJECT_TRANSACTION_SUCCESS: 'The transaction was successfully canceled',
  CANCEL_TRANSACTION_SUCCESS: 'Транзакция успешно отменена',
  DASHBOARD_VIDEOS_COUNT: 'Videos count',
  DASHBOARD_CHANNELS_COUNT: 'Channels count',
  DASHBOARD_CHANNEL_CONNECT_REQUESTS: 'Connect channel requests count',
  DASHBOARD_HAS_SIGNATURE: 'Signature available',
  DASHBOARD_HASNT_SIGNATURE: 'Signature not available',
  DASHBOARD_HAS_PAYMENT_REQUISITES: 'Payment requisites available',
  DASHBOARD_HASNT_PAYMENT_REQUISITES: 'Payment requisites not available',
  DASHBOARD_USERS_COUNT: 'Users count',
  DASHBOARD_TODAY_COUNT: 'Today',
  DASHBOARD_IN_WORK_COUNT: 'In work',
  DASHBOARD_PAYMENT_REQUEST_IN_WORK_SUM: 'Current amount of requests',
  DASHBOARD_CHANNELS_WITHOUT_ADDENDUM: 'Channels unrelated to the application',
  DASHBOARD_ERRORS_COUNT: 'Number of errors today',
  DASHBOARD_TRANSACTION_WAITING_CONFIRM_COUNT: 'Number of transactions awaiting confirmation',
  DASHBOARD_TRANSACTION_MANAGEMENT: 'Transaction management',
  DASHBOARD_PAYMENTS_MANAGEMENT: 'Payments management',
  DASHBOARD_USERS_CURRENT_MONTH_COUNT: 'This month',
  DASHBOARD_CONTRACTORS_INNER_ACCOUNTS_BALANCE_AMOUNT: 'Debt to counterparties',
  INSTRUCTIONS: 'Instructions',
  MAILINGS: 'Mailings',
  OOPS: 'Oops',
  SOMETHING_WENT_WRONG: 'Something went wrong here',
  PAGE_NOT_FOUND: 'Page not found',
  EMPTY_REPORT_CANNOT_CREATE_FINANCIAL_REPORT:
    'Due to the lack of contracts it is not possible to upload the financial report',
  TOTAL_TRANSACTIONS_SUM: 'Total transactions sum',
  SAVE: 'Save',
  DEPENDENT_TRANSACTIONS: 'Dependent transactions',
  DEPENDS_ON: 'Depends on',

  SUBJECT: 'Subject',
  CHOOSE_USERS: 'Choose users',
  CHOOSE_ALL: 'Choose all',
  CREATE_MAILING: 'Create mailing',
  MULTILINGUAL: 'Multilingual',
  MARKDOWN: 'Markdown',
  HTML: 'HTML',
  PREVIEW: 'Preview',
  SUCCESS_SEND_MAILING: 'The mailing has been successfully completed',
  EDIT_MAILING: 'Edit mailing',
  CONFIRM_DELETE_MAILING: 'Are you sure you want to delete the newsletter?',
  EMPTY: 'Empty',

  CREATE_TICKET: 'Create an appeal',
  TICKET_STATUS: 'Appeal staus',
  OPEN_TICKETS: 'Open',
  CLOSED_TICKETS: 'Closed',
  TICKET_TOPIC: 'Appeal theme',
  TICKET_TEXT: 'Appeal text',
  TICKET_AUTHOR: 'Author',
  OPEN_SUPPORT_TICKET: 'Open appeal',
  TICKETS_NOT_FOUND: 'You have no open support appeals',
  CREATE_TICKET_MARKETING_DESCRIPTION:
    'If you have a question, problem or suggestion\nContact us by creating an appeal.\nWe will certainly consider it.',
  SUPPORT_TICKET_REASON: 'Reason for opening an appeal',
  WRITE_MESSAGE: 'Write message',
  MESSAGE_COUNT: 'Message count',
  TICKET_OPENED_AT: 'Opened',
  CONFIRM_CLOSE_APPEAL: 'Are you sure you want to close the appeal?',
  CLOSE_APPEAL: 'Close the appeal',
  APPEAL_WAS_CLOSED: 'The appeal was closed',
  CONFIRM: 'Confirm',
  ATTENTION: 'Attention',
  UPDATE_CLIENT_NOTICE: 'The site has been updated. To get changes, refresh the page.',
  REFRESH: 'Refresh',
  REVOKE_ACCESS: 'Revoke access to the YouTube account',
  REVOKE_ACCESS_DESCRIPTION:
    'If you want to revoke access to your YouTube account, you can do so on <permissonslink>the Google Account Access Control page</permissonslink>.',
  SHOW_ALL: 'Show all',
  HIDE: 'Hide',
  CHANNEL_HIDDEN_FROM_YOUTUBE:
    'This channel is no longer on the multichannel network.\nTo upload your income report, go to <redirect>Financial Reports.</redirect>',
  HIDDEN_CHANNELS_AND_VIDEO_FROM_YOUTUBE:
    'This channel is not on a multichannel network, and the video has been hidden.\nTo upload your income report, go to <redirect>Financial Reports.</redirect>',
  VIDEO_HIDDEN_FROM_YOUTUBE:
    'This video has been hidden.\nTo upload your income report, go to <redirect>Financial Reports.</redirect>',
  NOTICE: 'Notice',
  CHANNEL_NOT_AVAILABLE: 'This channel is not available',
  NOT_APPLICABLE: 'N/A',
  RESET_PASSWORD_TOKEN_EXPIRED: 'The password reset token is out of date. Please request a new password reset.\n',
  REGISTRATION_CODE_CONFIRMATION: 'Registration confirmation',
  REGISTRATION_CODE_CONFIRMATION_DESC: 'An email <email></email> with a confirmation code has been sent to you',
  CODE_DIDNT_COME: 'Didn\'t you get the code?',
  SEND_AGAIN: 'Send again',
  BACK_TO_REGISTER: 'Back to registration',
  CONFIRMATION_CODE_IS_INVALID: 'Confirmation code is invalid',
  CREDENTIALS_ARE_OUT_OF_DATE: 'Your account information is out of date, Please re-register',
  ACCESSIBILITY: 'Accessibility',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ACTIVE_VIDEO: 'Active',
  INACTIVE_VIDEO: 'Inactive',
  PERIOD_START_DATE: 'Period started at',
  PERIOD_END_DATE: 'Period finish at',
  TAX_PERIOD: 'Tax period',
  SAVE_AS_DRAFT: 'Save as draft',
  DRAFT: 'Draft',
  EDIT_CHAPTER: 'Edit chapter',
  CREATE_CHAPTER: 'Create chapter',
  CREATE_NEW_CHAPTER: 'Create new chapter',
  CREATE_ARTICLE: 'Create article',
  CONFIRM_DELETE_ARTICLE: 'Are you sure you want to delete the article?',
  CONFIRM_DELETE_CHAPTER: 'Are you sure you want to delete the chapter?',
  KNOWLEDGE_BASE_DESCRIPTION:
    'Here are the answers to the top questions about the Play Network Personal Area and your opportunities in the ecosystem.\n' +
    'If you can\'t find the question you\'re looking for in the list, or if you don\'t find the answer you need, please contact <supportlink>support directly</supportlink> .\n' +
    'We are always happy to help you!',
  NOT_AVAILABLE: 'Not available',
  NOT_SPECIFIED: 'Not specified',
  SPECIFY_HEIGHT: 'Specify height',
  SPECIFY_WIDTH:
    'Specify the width (by default it is set in px, to set the value in percent, specify "%" at the end of the value)',
  APPLICATION_SETTINGS: 'Application settings',
  SUCCESS_UPDATE_SETTINGS: 'Settings was updated',

  ADS_REQUEST_NOTICE_RECIPIENT: 'Recipient of notifications about new advertising requests',
  CONNECT_CHANNEL_REQUEST_NOTICE_RECIPIENT: 'Recipient of notifications about connection requests',
  CHANGE_PAYMENT_REQUISITES_NOTICE_RECIPIENT: 'Recipient of notifications about changes in payment registers',
  PAYMENT_REQUEST_NOTICE_RECIPIENT: 'Recipient of notifications about payment requests',
  SUPPORT_TICKET_NOTICE_RECIPIENT: 'Recipient of notifications about new support tickets',
  NEED_SEND_TRANSACTION_EMAIL_NOTIFICATION: 'Notifies users when transactions are created',
  DOWNLOADS: 'Downloads',
  DOWNLOADING: 'Downloading',
  SUCCESS_DOWNLOAD_FILE: 'File downloaded successfully',
  GENERATE_INCOME_REPORT: 'Download Channel Revenue Report',
  CONTRACT_ON_CHANNEL: 'Contract for the channel',
  NEED_SIGNATURE_NOTICE: 'Signature required',
  RE_SIGNATURE: 'Re-sign',
  SET_SIGNATURE: 'Sign',
  SIGNATURE_HERE: 'Sign here',
  APPROVE_CONNECT_CHANNEL_REQUEST: 'YouTube Channel connection request approve',
  SIGNED: 'Signed',

  HAS_TAX_ON_PROFITS: 'Check if you work with VAT',
  INTEGRATION_ENABLE: 'Integration enable',
  INTEGRATION_PRICE: 'Integration price',
  ADVANCED_INTEGRATION_ENABLE: 'Advanced integration enable',
  ADVANCED_INTEGRATION_PRICE: 'Advanced integration price',
  POST_ENABLE: 'Post enable',
  POST_PRICE: 'Post price',
  PRE_ROLL_ENABLE: 'Pre roll enable',
  PRE_ROLL_PRICE: 'Pre roll price',
  PRODUCT_PLACEMENT_ENABLE: 'Product placement enable',
  PRODUCT_PLACEMENT_PRICE: 'Product placement price',
  SPECIAL_PROJECT_ENABLE: 'Special project enable',
  SPECIAL_PROJECT_PRICE: 'Special project price',
  EXPECTED_VIEWS_COUNT: 'Expected views count',
  TAX: 'Income tax',
  RELEASE_DATE: 'Release at',
  COMMENT: 'Comment',
  CHOSEN: 'Chosen',
  CONFIRM_DELETE_ADS_REQUEST: 'Are you sure you want to delete the ads request?',
  ADVERTISEMENT_REQUESTS: 'Ads requests',
  SUCCESS_ADS_REQUEST_REJECT: 'Request for advertising has been successfully rejected',
  SUCCESS_ADS_REQUEST_CONFIRM: 'Request for advertising has been successfully approved',
  PRICE: 'Price',
  PRICE_WITHOUT_TAX: 'Cost excluding tax',
  CREATE_ADS_REQUEST: 'Create an ad request',
  ADS_REQUEST_COMMENT_PLACEHOLDER:
    'Tell us more about future content:\n\nPlanned topic of the video, Invited guests\nExpected number of views, Other',
  ADS_REQUESTS_NOT_FOUND: 'Find advertisers for your content!',
  CONFIRM_APPROVE_ADS_REQUEST: 'Are you sure you want to approve the advertising request?',
  CREATE_ADS_REQUEST_MARKETING_DESCRIPTION:
    'This section is designed to let us know about free advertising slots.\n\n' +
    'Together with <playersteamlink>Players</playersteamlink> and other partners, we will help you find sponsors \n' +
    'for upcoming content, as well as place information in the <playerscontentbotlink>Players Content Bot.</playerscontentbotlink>',
  COOKIE_NOTICE: 'We use cookies to make the site more user-friendly.',
  I_AGREE: 'I agree',
  CONNECT_YOUTUBE_CHANNEL: 'Connecting the YouTube Channel',
  NOTICE_APPROVE_CONNECT_CHANNEL_REQUEST: 'To approve joining the YouTube channel, you need to specify additional data',
  SUCCESS_APPROVE_CONNECT_CHANNEL: 'YouTube Channel join request successfully approved',
  SUCCESS_CREATE_CONNECT_CHANNEL_REQUEST: 'Connect channel request successfully created',
  MORE_TRANSACTION_FIRST: 'More transaction first',
  LESS_TRANSACTION_FIRST: 'Less transaction first',

  CASH_OUT: 'Cash out',
  MC_PAY_FILTER: 'MC PAY affiliation',
  FOR_MC_PAY: 'Integration with MCPAY',
  MC_PAY_STATUS: ' MCPAY request status',
  ['MC_PAY_STATUS_' + McPayCreateTransactionStatus.WAITING_SEND_TO_MC_PAY]: 'Waiting',
  ['MC_PAY_STATUS_' + McPayCreateTransactionStatus.ERROR]: 'Error',
  ['MC_PAY_STATUS_' + McPayCreateTransactionStatus.SUCCESS]: 'Success',
  MC_PAY_STATUS_WAITING_SEND_TO_MC_PAY: 'Waiting send',
  NOT_FOR_MC_PAY: 'No integration with MCPAY',
  MC_PAY_STATUS_SUCCESS: 'Success',
  MC_PAY_STATUS_ERROR: 'Faulted',
  MC_PAY_REGISTER: 'Auth',
  MC_PAY_ALREADY_REGISTER: 'Success Authorized',
  MC_PAY_DESCRIPTION: 'Reliable payment system mcpay.io',
  MC_PAY_DESCRIPTION_LIST_ITEM_ONE: 'Instant money withdrawal',
  MC_PAY_DESCRIPTION_LIST_ITEM_SECOND: 'Access to the entire transaction history',
  MC_PAY_DESCRIPTION_LIST_ITEM_THIRD:
    'Withdrawal of funds in 12 different ways, including bank account and phone number',
  MC_PAY_INTEGRATION_NOTICE_RECIPIENT: 'Recipient of notifications on interaction with mcpay.io',
  IMPORT_YOUTUBE_DATA_RECIPIENT: 'Recipient of notifications about importing data from YouTube',
  MC_PAY_WITHDRAWAL_REQUEST: 'Confirmation of withdrawal to MC PAY',
  CONFIRM_MC_PAY_OPERATIONS_CONFIRMATION_MESSAGE: 'Are you sure you want to confirm sending transactions to MC PAY?',
  SUCCESS_CONFIRM_MC_PAY_OPERATIONS: 'Transactions will soon be sent to MC PAY',
  WITHDRAWAL_TO_MC_PAY_NO_OWNER_USER_ATTENTION: 'Sorry, interaction with the mcpay.io is only available to the manager',
  MC_PAY_INTEGRATION_TEMP_UNAVAILABLE:
    'Technical work is underway. Integration with mcpay.io is temporarily unavailable, try later',
  MC_PAY_INTEGRATION_ENABLE: 'Integration with mcpay.io',
  BALANCE_FROM_MC_PAY: 'The balance from the mcpay.io system is displayed',
  BALANCE_FROM_PN: 'The balance from the Play Network system is displayed',

  JURISDICTION: 'Jurisdiction',

  PAYMENT_REQUEST_DESTINATION: 'Destination',
  MC_PAY_DESCRIPTION_LIST_ITEM_FOURTH: 'Provider charges may apply',
  PN_TAX_INFO: 'Transfer fee',
  INPUT_PLACEHOLDER_PAYMENT_REQUEST_DESTINATION: 'Select the destination to which the money will go',
  SUCCESS_CONFIRM_PAYMENT_REQUEST: 'Payment request successfully approved',
  SUCCESS_REJECT_PAYMENT_REQUEST: 'Payment request successfully rejected',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.BY_REQUISITES]: 'By requisites',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.TO_MCPAY]: 'To mcpay.io',
  WITHDRAWAL_SUM_WITHOUT_FEE: 'Coming to your account',

  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.WISH]: 'Wish',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.PROBLEM]: 'Problem',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.QUESTION]: 'Question',
  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.OPEN]: 'Open',
  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.CLOSED]: 'Closed',

  ['ADS_REQUEST_STATUS_' + AdsStatus.IN_WORK]: 'In work',
  ['ADS_REQUEST_STATUS_' + AdsStatus.REJECTED]: 'Rejected',
  ['ADS_REQUEST_STATUS_' + AdsStatus.DONE]: 'Confirmed',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.IN_WORK]: 'In work',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.COMPLETE]: 'Complete',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.REJECT]: 'Reject',
  ['CONTRACT_STATUS_' + ContractStatus.ACTIVE]: 'Active',
  ['CONTRACT_STATUS_' + ContractStatus.EXPIRED]: 'Expired',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.IN_WORK]: 'Pending moderation',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.REJECTED]: 'Rejected',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.APPROVED]: 'Approved',
  ['TRANSACTION_STATUS_' + TransactionStatus.CONFIRMED]: 'Confirmed',
  ['TRANSACTION_STATUS_' + TransactionStatus.REJECTED]: 'Rejected',
  ['TRANSACTION_STATUS_' + TransactionStatus.WAITING_CONFIRM]: 'Waiting confirm',
  ['TRANSACTION_STATUS_' + TransactionStatus.CANCELED]: 'Canceled',

  ['ARTICLE_STATUS_' + ArticleStatus.DRAFT]: 'Draft',
  ['ARTICLE_STATUS_' + ArticleStatus.PUBLISHED]: 'Publish',

  ['TRANSACTION_TYPE_' + TransactionType.PAYMENT_OF_TAX]: 'Withholding tax',
  ['TRANSACTION_TYPE_' + TransactionType.PAYMENT_YOUTUBE_REVENUE_FOR_CONTRACTOR]: 'Payment of remuneration',
  ['TRANSACTION_TYPE_' + TransactionType.WITHDRAWAL]: 'Withdrawal',
  ['TRANSACTION_TYPE_' + TransactionType.ADJUSTMENT_BALANCE]: 'Balance Adjustment',
  ['TRANSACTION_TYPE_' + TransactionType.PAYMENT_FROM_CONTRACTOR_TO_PLAYNETWORK]:
    'Payment for services by a counterparty',
  ['TRANSACTION_TYPE_' + TransactionType.FROM_YOUTUBE_TO_PLAYNETWORK]: 'Payment of remuneration from Youtube',
  ['TRANSACTION_TYPE_' + TransactionType.WITHDRAWAL_TO_MC_PAY]: 'Withdrawal to mcpay.io',
  ['TRANSACTION_TYPE_' + TransactionType.WITHDRAWAL_COMMISSION]: 'Withdrawal commission',

  ['REQUISITES_HISTORY_' + 'beneficiary_account_number']: 'Account number',
  ['REQUISITES_HISTORY_' + 'beneficiary_bank_swift_number']: 'SWIFT/BIC/Routing',
  ['REQUISITES_HISTORY_' + 'en_beneficiary_address']: 'Address (en)',
  ['REQUISITES_HISTORY_' + 'ru_beneficiary_address']: 'Address (ru)',
  ['REQUISITES_HISTORY_' + 'en_beneficiary_name']: 'Name (en)',
  ['REQUISITES_HISTORY_' + 'ru_beneficiary_name']: 'Name (ru)',

  USER_AGREEMENT: 'Terms of use',
  REPORT_GROUP_ADS: 'Advertising revenue',
  REPORT_GROUP_SUBSCRIPTION: 'Subscription revenue',
  REPORT_GROUP_COMPENSATION: 'Compensation income',
  REPORT_GROUP_SHORTS: 'Revenue from YouTube Shorts',
  REPORT_GROUP_OTHER: 'Other',
  IS_REFERRAL: 'Is referral',
  REFERRAL: 'Referral',
  WITH_REFERRAL: 'With referral',
  WITHOUT_REFERRAL: 'Without referral',
  NOT_COPIES: 'Not copies',
  IS_COPY: 'Is copy',
  COPIES: 'Copies',
  UPLOAD_CUSTOM_TEMPLATE: 'Upload custom template',
  DELETE_CUSTOM_TEMPLATE: 'Delete custom template',
  DOWNLOAD_CUSTOM_TEMPLATE: 'Download custom template',
  HAS_CUSTOM_TEMPLATE: 'Template',
  SUCCESS_TEMPLATE_UPLOAD: 'Template successfully uploaded',
  CMS_REVENUE: 'CMS revenue',
  GO_TO_CHAT: 'Go to chat',
  USER_FIELDS: 'User\'s fields',
} as const;
