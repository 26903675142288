import React, {useMemo} from 'react';
import {FormattedDate, FormattedMessage, FormattedNumber, FormattedTime, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {IConnectChannelRequestDto} from '../../../api/connect-channel-api/IConnectChannelRequestDto';
import SVG from 'react-inlinesvg';
import {FilterRequestStatus} from '../../../api/connect-channel-api/FilterRequestStatus';
import {DefaultTable} from '../../../components/default-table';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {TableUtils} from '../../../components/table-utils';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import {faX} from '@fortawesome/free-solid-svg-icons/faX';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface ConnectChannelRequestsProps {
  connectRequests: Array<IConnectChannelRequestDto>;
}

const ICONS = {
  VIEWS: require('../../../images/svg/Visible.svg'),
  SUBSCRIBERS: require('../../../images/svg/Group.svg'),
  VIDEO_LANE: require('../../../images/svg/Video-Lane.svg'),
};

export const ConnectChannelRequestsTable = ({connectRequests}: ConnectChannelRequestsProps) => {
  const intl = useIntl();
  const data = connectRequests;
  const columns = useMemo(() => {
    return [
      {
        id: 'Preview',
        Header: '',
        maxWidth: 50,
        minWidth: 30,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyConnectChannelRequestRoute(value.id)}>
              <img src={tryGetPreviewOrStub(value.thumbnailsDTO).url} alt="image" className={'w-100 rounded'} />
            </Link>,
          );
        },
      },
      {
        id: 'Title',
        maxWidth: 250,
        minWidth: 150,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            <a href={Routes.getExternalYoutubeChannelRoute(value.channelId)} target={'_blank'} rel="noreferrer">
              {value.title}
            </a>,
          );
        },
      },
      {
        id: 'User',
        Header: intl.formatMessage({id: 'USER'}),
        maxWidth: 200,
        minWidth: 180,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyUserManagementRoute(value.user_id)}>{value.user_name}</Link>,
          );
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 150,
        minWidth: 100,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            value.contractor_id != null ? (
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>{value.contractor_name}</Link>
            ) : (
              '--'
            ),
          );
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 70,
        minWidth: 70,
        forceApplySize: true,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          let colorVariant;
          let icon;
          switch (value.requestStatus) {
            case FilterRequestStatus.APPROVED:
              icon = faCheck;
              colorVariant = 'success';
              break;
            case FilterRequestStatus.IN_WORK:
              icon = faClock;
              colorVariant = 'primary';
              break;
            case FilterRequestStatus.REJECTED:
              icon = faX;
              colorVariant = 'danger';
              break;
          }

          return TableUtils.renderBaseContent(
            <FontAwesomeIcon icon={icon} className={`text-${colorVariant}`} size={'xl'} />,
            {className: 'flex d-flex justify-content-center'});
        },
      },
      {
        id: 'SubscriberCount',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUBSCRIBERS'})),
        maxWidth: 110,
        minWidth: 100,
        forceApplySize: true,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderNumericContent(
            <div style={{fontSize: 12}}>
              <div>
                <FormattedNumber value={value.subscriberCount} notation={'compact'} />
                <span className="svg-icon svg-icon-lg svg-icon-success ml-1"><SVG src={ICONS.SUBSCRIBERS} /></span>
              </div>
              <div>
                <FormattedNumber value={value.viewCount} notation={'compact'} />
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1"><SVG src={ICONS.VIEWS} /></span>
              </div>
              <div>
                <FormattedNumber value={value.videoCount} notation={'compact'} />
                <span className="svg-icon svg-icon-lg svg-icon-warning ml-1"><SVG src={ICONS.VIDEO_LANE} /></span>
              </div>
            </div>,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
